import { translate } from "@/i18n/mod.ts";
import { BadgeContainer } from "@/components/BadgeContainer.tsx";

export default function AppleStoreBadge() {
  return (
    <BadgeContainer>
      <a
        href="https://apps.apple.com/br/app/crm-syonet/id1090416158"
        target="_blank"
        className="m-0 p-0"
      >
        <img
          alt={translate("Alt baixar app")}
          src={translate("Apple Badge")}
          width="100%"
        />
      </a>
    </BadgeContainer>
  );
}
